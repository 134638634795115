#header {
  background: url(../../images/header-bg-1.png) center bottom no-repeat;
  background-size: cover;
  padding-top: 30px;
  margin-bottom: 30px;
  position: relative;
  @media (max-width: 767px) {
    padding-top: 0;
  }
  .menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    &_item {
      padding: 0.5rem 0;
      a {
        font-family: Montserrat-SemiBold;
        color: #ffffff;
        font-size: 18px;
        text-transform: uppercase;
        transition: transform 0.6s ease-in-out;
        border-bottom: 2px solid transparent;
        display: inline-block;
        padding: 0;
        &:hover {
          border-bottom: 2px solid #fff;
        }
      }
    }
  }
  .header_content {
    margin-top: 120px;
  }
  h3 {
    font-family: Montserrat-SemiBold;
    color: #ffffff;
    font-size: 36px;
    letter-spacing: 1.5px;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 18px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 22px;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    button, a {
      font-family: Montserrat-SemiBold;
      font-size: 20px;
      background-color: #6bced8;
      border: 2px solid #ffffff;
      text-transform: uppercase;
      width: 48%;
      border-radius: 12px;
      padding: 10px 0;
      @media (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    position: absolute;
    &.fixed-top {
      position: fixed;
    }
    .navbar-brand {
      .logo {
        margin-bottom: 20px;
        height: 80px;
      }
    }
    .navbar-toggler {
      padding: 0;
      border: none;
      &.close {
        .navbar-toggler-icon {
          height: 50px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='1' fill='rgb(255,255,255)' stroke-linecap='butt' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z'/%3E%3C/svg%3E");
          position: relative;
          top: 7px;
          left: 6px;
        }
        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
          opacity: 1;
        }
      }
      &-icon {
        width: 50px;
        height: 41px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='2.5' stroke-linecap='butt' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
      &:active,
      &:focus {
        outline: none;
      }
    }
    &.fixed-top {
      flex-direction: row;
      background: rgb(6,149,163);
      background: -webkit-linear-gradient(left, rgba(6,149,163,1) 0%, rgba(36,222,241,1) 25%, rgba(36,222,241,1) 75%, rgba(6,149,163,1) 100%);
      background: -o-linear-gradient(left, rgba(6,149,163,1) 0%, rgba(36,222,241,1) 25%, rgba(36,222,241,1) 75%, rgba(6,149,163,1) 100%);
      background: linear-gradient(to right, rgba(6,149,163,1) 0%, rgba(36,222,241,1) 25%, rgba(36,222,241,1) 75%, rgba(6,149,163,1) 100%);
      box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.1);
      background-size: 100% 100%;
      align-items: center;
      padding-left: 1rem;
      .navbar-brand {
        .logo {
          height: 63px;
          margin-bottom: 0;
        }
        &:after {
          content: '';
          border-right: 2px solid #fff;
          height: 61px;
          position: absolute;
          top: 27px;
          padding-left: 20px;
        }
      }

      .navbar-collapse {
        justify-content: center;
        padding-left: 0;
      }
      .menu {
        &_item {
          display: flex;
          float: none;
          align-items: center;
          span {
            padding: 0 20px;
            font-size: 28px;
            color: #fff;
            position: relative;
            top: -3px;
          }
        }
      }
      .show,
      .collapsing{
        .menu {
          &_item {
            span {
              display: none;
            }
          }
        }
      }
    }
    @media (min-width: 768px) and (max-width: 1365px) {
      &.fixed-top {
        .navbar-brand {
          .logo {
            height: 63px;
            margin-bottom: 0;
          }
          &:after {
            content: '';
            border-right: none;
            padding-left: 0;
          }
        }
      }
    }
    @media (max-width: 1023px) {
      flex-direction: row;
      &.fixed-top {
        background: url('../../images/shadow.png') no-repeat;
        background-size: cover;
        .navbar-brand {
          .logo {
            height: 63px;
            margin-bottom: 0;
          }
          &:after {
            content: '';
            border-right: none;
            padding-left: 0;
          }
        }
      }
      .navbar-collapse {
        width: 100%;
        padding: 0;
        .menu {
          &_item {
            padding: 0.3rem;
            a {
              font-size: 18px;
              &:after {
                content: '';
                padding-left: 0;
              }
            }
          }
        }
      }
    }
    @media (min-width: 1200px) {
      .container-fluid {
        flex-direction: column;
      }
      &.fixed-top .container-fluid {
        flex-direction: row;
      }
    }
  }
}
