.howItWorks {
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 767px) {
    text-align: center;
    margin-bottom: 80px;
  }
  #how-it-works {
    position: absolute;
    top: -100px;
  }
  img {
    width: 100%;
    position: relative;
    top: -65px;
    @media (max-width: 1023px) {
      top: 0;
    }
    @media (max-width: 767px) {
      top: 30px;
    }
  }
}
