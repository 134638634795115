@font-face {
  font-family: 'Montserrat-Regular';
  font-weight: 400;
  font-style: normal;
  src: url('../src/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  font-weight: 400;
  font-style: normal;
  src: url('../src/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  font-weight: 400;
  font-style: normal;
  src: url('../src/fonts/Montserrat-Medium.ttf') format('truetype');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Montserrat-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-family: Montserrat-SemiBold;
  font-size: 28px;
  text-transform: uppercase;
  color: #05D0E5;
  margin-bottom: 15px;
  margin-top: 50px;
}

p {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 30px;
  letter-spacing: 0.18px;
}
p:last-of-type {
  margin-bottom: 0;
}


@media (max-width: 767px) {
  h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
  }
}

@media (min-width: 1432px) {
  .container {
    max-width: 1350px;
  }
}
