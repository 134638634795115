.aboutUs {
  position: relative;
  margin-bottom: 80px;
  #about {
    position: absolute;
    top: -100px;
  }
  .whiteBlock {
    position: relative;
    margin-top: 110px;
    @media (max-width: 767px) {
      margin-top: 10px;
    }
    #features {
      position: absolute;
      top: -150px;
    }
    .banner {
      position: absolute;
      background: url(../../images/about-img-bg.png) no-repeat right;
      background-size: 50% 100%;
      height: 100%;
      width: 100%;
      @media (max-width: 767px) {
        background-size: contain;
        background-position-y: 4%;
      }
    }
    .featuresTitle {
      order: -1;
      @media (max-width: 767px) {
        margin-bottom: 100px;
        text-align: center;
        h2 {
          margin: 35px 0;
        }
      }
    }
    .woman {
      position: relative;
      .whiteBlock_card{
        box-shadow: none;
        background: url('../../images/card_bg.png') transparent no-repeat;
        background-size: 100% 100%;
        height: 771px;
        bottom: -7px;
        position: absolute;
        padding-top: 205px;
        left: auto;
        right: auto;
        z-index: 2;
        @media screen and (device-aspect-ratio: 40/71) {
          height: 100%;
        }
        @media (max-width: 767px) {
          position: relative;
        }
        .img {
          position: absolute;
          top: -110px;
          z-index: -1;
          img {
            height: 250px;
          }
        }
      }
    }
    .man {
      .whiteBlock_card {
        box-shadow: none;
        height: 770px;
        background: url('../../images/card_bg1.png') transparent no-repeat;
        background-size: 100% 100%;
        z-index: 2;
        @media screen and (device-aspect-ratio: 40/71) {
          height: 100%;
        }
        @media (max-width: 767px) {
          height: 660px;
        }
        hr {
          bottom: 185px;
          z-index: -2;
          @media (max-width: 767px) {
            bottom: 135px;
          }
        }
      }
      .img {
        position: absolute;
        bottom: -80px;
        left: 23%;
        z-index: -1;
        img {
          height: 360px;
          @media (max-width: 767px) {
            height: 300px;
          }
        }
      }
      @media (max-width: 767px) {
        overflow-x: hidden;
        column-width: 250px;
      }
      @media (min-width: 546px) and (max-width: 575px){
        overflow-x: hidden;
        column-width: auto;
        min-height: 800px;
      }
    }
    &_card {
      background: #fff;
      min-height: 600px;
      border-radius: 50px;
      box-shadow: 6px 6px 0px 0px rgba(0,0,0,0.1);
      margin:0 auto 30px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      text-align: center;
      @media screen and (device-aspect-ratio: 40/71) {
        width: 100%;
      }
      @media (max-width: 767px) {
        height: auto;
        padding: 40px;
        p {
          margin-bottom: 30px;
        }
      }
      @media (min-width: 768px) and (max-width: 1024px){
        padding: 30px;
      }
      @media (min-width: 1024px) and (max-width: 1199px){
        padding: 20px;
      }
      h3 {
        font-size: 22px;
        margin: 20px 0;
        font-family: Montserrat-SemiBold;
        color: #666;
      }
      p {
        text-align: justify;
        text-justify: inter-word;
      }
      hr {
        margin: 30px 0;
        border: 1px solid #05d0e5;
        width: 100px;
        position: absolute;
        bottom: 15px;
      }
    }
  }
  ul {
    @media (max-width: 767px) {
      padding-left: 15px;
      li {
        margin-bottom: 8px;
      }
    }
  }
  .columns {
    columns: 2;
  }
  .goals {
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
