.contacts {
    background: url(../../images/footer-bg-2.png) top center no-repeat;
    background-size: cover;
    color: #fff;
    margin: 0;
    padding: 500px 0 0 0;
    position: relative;
    border: 0;
    #contact {
        position: absolute;
        top: -100px;
    }
    @media (max-width: 1024px) {
        background: url(../../images/footer-bg-4.png) top no-repeat;
        padding-top: 350px;
        background-size: cover;
    }
    @media (max-width: 767px) {
        padding-top: 295px;
        background-size: cover;
    }
    @media (max-width: 468px) {
        background: url(../../images/footer-bg-4.png) top no-repeat;
        padding-top: 205px;
        background-size: cover;
    }
    .footer-container {
      @media (max-width: 639px) {

      }
    }
    .contacts-info {
        color: #fff;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
        line-height: 20px;
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 0;
        }
        a {
            color: #fff;
            font-size: inherit;
            &:hover,
            &:active,
            &:focus {
                color: #fff;
            }
        }
    }
    .footer {
        margin-bottom: 3rem;
        color: #fff;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
    }
    .footer-social-network {
        margin: 35px 0;
        padding: 0;
        display: flex;
        justify-content: left;
        li {
            margin: 0;
            padding: 0;
            list-style: none;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            a {
                display: block;
                width: 44px;
                height: 44px;
                line-height: 42px;
                background: #fff;
                border-radius: 50%;
                margin: auto auto;
                text-decoration: none;
                text-align: center;
                svg {
                    width: 50%;
                }
            }
        }
    }
    @media (max-width: 767px) {
        .contacts-info {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 0;
        }
        .footer {
            margin-bottom: 20px;
        }
    }
    @media (max-width: 468px) {
        .contacts-info {
            font-size: 14px;
        }
        .footer {
            margin-bottom: 20px;
            font-size: 14px;
        }
        .footer-social-network {
            margin: 40px 0;
            li {
                margin-right: 18px;
                a {
                    width: 34px;
                    height: 34px;
                    line-height: 32px;
                }
            }
        }
    }
}

@media (min-width: 1200px) and (max-width: 1799px){
  .contacts {
      padding: 650px 0 0 0;
  }
}
@media (min-width: 1800px){
    .contacts {
        padding: 850px 0 0 0;
    }
}
