.network {
  #network {
    position: relative;
    #why-wallace {
      position: absolute;
      top: -100px;
    }
  }
  .banner {
    background: url(../../images/network-img.png) no-repeat center;
    width: 100%;
    min-height: 350px;
    background-size: 100% 100%;
    margin-bottom: 80px;
    padding: 10px;
    @media (min-width: 1921px) {
      img {
        position: relative;
        top: -30px;
      }

    }
    @media (max-width: 1199px) {
      background-size: cover;
      background-position: 30%;

    }
    @media (max-width: 767px) {
      margin-bottom: 50px;
      background-position: 14% 0%;
    }
  }
  p {
    display: flex;
    align-items: center;
    span {
      color: #05d0e5;
      font-family: Montserrat-Medium;
      font-size: 28px;
      padding-right: 20px;
    }
  }
  .divider {
    background: rgb(6,149,163);
    background: -webkit-linear-gradient(left, rgba(6,149,163,1) 0%, rgba(36,222,241,1) 25%, rgba(36,222,241,1) 75%, rgba(6,149,163,1) 100%);
    background: -o-linear-gradient(left, rgba(6,149,163,1) 0%, rgba(36,222,241,1) 25%, rgba(36,222,241,1) 75%, rgba(6,149,163,1) 100%);
    background: linear-gradient(to right, rgba(6,149,163,1) 0%, rgba(36,222,241,1) 25%, rgba(36,222,241,1) 75%, rgba(6,149,163,1) 100%);
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0 50px;
    padding: 50px 0;
    text-align: center;
    @media (max-width: 767px) {
      margin-bottom: 20px;
      background-size: 100% 100%;
    }
    &_quote {
      position: relative;
      padding: 0 20px;
      &:before {
        content: '';
        background: url(../../images/quote.png) no-repeat;
        background-size: contain;
        position: absolute;
        height: 70px;
        width: 45px;
        left: -30px;
        top: 20px;
        @media (max-width: 767px) {
          height: 20px;
          width: 20px;
          left: 0;
          top: calc(100%/2);
        }
      }
      &:after {
        content: '';
        background: url(../../images/quote.png) no-repeat;
        background-size: contain;
        position: absolute;
        height: 70px;
        width: 45px;
        right: -30px;
        top: -20px;
        transform: rotate(180deg);
        @media (max-width: 767px) {
          height: 20px;
          width: 20px;
          right: 0;
          top: calc(100%/2);
          bottom: 0
        }
      }
    }
    h4 {
      color: #ffffff;
      font-family: Montserrat-Medium;
      font-size: 20px;
      font-style: italic;
    }
    p {
      color: #ffffff;
      font-family: Montserrat-SemiBold;
      font-size: 15px;
      display: block;
    }
  }
  .logos {
    @media (max-width: 767px) {
      div {
        margin-bottom: 20px;
      }
    }
    img {
      height: 50px;
      display: block;
      margin: 0 auto 10px;
    }
    p{
      text-align: center;
      display: block;
      margin-bottom: 20px;
    }
  }
}
