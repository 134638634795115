#features {
    background: url(../../images/versions-img-bg.png) 50% 55% no-repeat;
    background-size: contain;
    color: #666;
    overflow: hidden;
    @media (max-width: 1024px) {
        background: url(../../images/versions-md-img-bg.png) 100% 60% no-repeat;
        background-size: contain;
    }
    @media (max-width: 736px) {
        background: url(../../images/versions-md-img-bg.png) 100% 75% no-repeat;
        background-size: contain;
    }
    @media (min-width: 992px) {
      .container {
          min-height: 950px;
      }
    }
    @media (min-width: 576px) {
      .container {
          min-height: 600px;
      }
    }
    .feature-block {
        padding: 3.5rem;
        @media (max-width: 1024px) {
            padding: 3.5rem;
        }
        @media (max-width: 768px) {
            padding: 1.5rem;
        }
        h2 {
            font-size: 30px;
            margin-bottom: 60px;
            @media (max-width: 1024px) {
                font-size: 18px;
                margin-bottom: 50px;
            }
            @media (max-width: 768px) {
                margin-bottom: 25px;
            }
        }
        p {

        }
        .feature-block-img {
            @media (max-width: 767px) {
                padding: 0;
            }
            img {
                width: 100%;
            }
            .img-left {
                float: left;
            }
            .img-right {
                float: right;
            }
            &:first-child {
                padding-right: 10%;
            }
            &:last-child {
                padding-left: 10%;
            }
            .img-flare {
                position: absolute;
                width: 50%;
            }
            .img-left-flare {
                left: -100px;
                bottom: -100px;
                @media (max-width: 1024px) {
                    left: -70px;
                    bottom: -70px;
                }
                @media (max-width: 768px) {
                    left: -70px;
                    bottom: -70px;
                }
                @media (max-width: 575px) {
                    left: -40px;
                    bottom: -40px;
                }
                @media (max-width: 414px) {
                    left: -30px;
                    bottom: -30px;
                }
            }
            .img-right-flare {
                right: -100px;
                bottom: -100px;
                @media (max-width: 1024px) {
                    right: -70px;
                    bottom: -70px;
                }
                @media (max-width: 768px) {
                    right: -70px;
                    bottom: -70px;
                }
                @media (max-width: 575px) {
                    right: -40px;
                    bottom: -40px;
                }
                @media (max-width: 414px) {
                    right: -30px;
                    bottom: -30px;
                }
            }
        }
        .feature-block-txt {
            padding: 1rem;
            @media (max-width: 1024px) {
                padding: 1rem 1rem 3.5rem 1rem;
            }
            @media (max-width: 1024px) {
                padding: 1rem 1rem 2rem 1rem;
            }
        }
    }
}

.color-black {
    color: #666;
}
